import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { withTendrilApi } from '../../components/TendrilApi';
import { ErrorMessage } from '../../components/ErrorMessage';
import { Form, Button, Message, Grid, Header, Segment, Modal, Icon } from 'semantic-ui-react';
import { ForgotPassword } from './ForgotPassword';


const initialStep = "initial";
const loggingInStep = "loggingIn";

class LoginBase extends Component {

    constructor(props) {
        super(props);
        this.state = { step: initialStep };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetState = this.resetState.bind(this);
    }


    componentDidMount() {
        this.resetState();
    }

    resetState() {
        this.setState({
            userName: '',
            password: '',
            error: null,
            step: initialStep
        });
    }




    async handleSubmit(event) {

        event.preventDefault();

        const { history } = this.props;

        if (!this.state.userName || !this.state.userName.trim()) {
            this.setState({ error: { message: "Username is required." } });
            return;
        }

        if (!this.state.password || !this.state.password.trim()) {
            this.setState({ error: { message: "Password is required." } });
            return;
        }


        this.setState({ error: null, step: loggingInStep });

        try {
            await this.props.tendrilApi.functions.login(this.state.userName, this.state.password);

            history.push("/");
        }
        catch (e) {
            this.setState({
                step: initialStep,
                error: e
            });
        }
    }



    render() {
        const { trigger } = this.props;

        return (
            <Modal trigger={trigger}
                closeIcon
                size='small'
                onOpen={this.resetState}
            >
                <Modal.Header>
                    <Header as='h2'>
                        <Icon name='sign in' />
                        <Header.Content>
                            Log in to ARQTEN
                        </Header.Content>
                    </Header>
                </Modal.Header>
                <Modal.Content>
                    <Form size='large' onSubmit={this.handleSubmit} loading={this.state.step === loggingInStep}>
                        <Form.Input autoFocus label='Username or email' icon='user' iconPosition='left' value={this.state.userName} autoComplete='username' onChange={event => this.setState({ userName: event.target.value })} />
                        <Form.Input label='Password' icon='lock' iconPosition='left' type='password' value={this.state.password} autoComplete='current-password' onChange={event => this.setState({ password: event.target.value })} />

                        <ErrorMessage error={this.state.error} />

                        <Button primary onClick={this.handleSubmit} content='Log in' />
                        &nbsp;&nbsp;
                        <ForgotPassword
                            trigger={<a style={{ cursor: 'pointer' }} tabIndex={0}> I forgot my password</a>}
                        />
                    </Form>
                </Modal.Content>
            </Modal>
        );
    }
}



export const Login = withTendrilApi(LoginBase);



