import React, { Component } from 'react';
import { Route, Switch, BrowserRouter, Redirect, Link } from 'react-router-dom';
import {
    Segment, Menu, Image, Dropdown, Icon, Button, Container
} from 'semantic-ui-react';
import { TendrilApiContext, withTendrilApi } from './TendrilApi';
import queryString from 'query-string';
import { UserAccount } from '../areas/user/UserAccount';
import { Login } from '../areas/user/LoginPage';


class TopNavBase extends Component {

    state = { customers: null };

    render() {

        const { location, history, match, tendrilApi } = this.props;
        const { user } = tendrilApi;


        let launcherMenu = [];
        let launcherValue = null;

        const addToLauncher = (text, url) => {
            launcherMenu.push({ text: text, value: url });
            if (match.url.startsWith(url))
                launcherValue = url;
        };

        if (user) {
            if (user.isRsgUser)
                addToLauncher("Admin", "/admin");

            for (let c of user.customers) {
                const url = `/customer/${c.customerOrgCode}`;
                addToLauncher(c.customerName, url);
            }
        }


        return (
            <div style={{ padding: '10px 20px' }}>
                <Menu
                    size='large'
                    secondary
                >
                    <Menu.Item as='a' header onClick={() => history.push("/")}>
                        <Image size='tiny' src='/images/logos/dark-logo.svg' style={{ marginRight: '1.5em' }} />
                    </Menu.Item>

                    {user && launcherMenu && launcherValue && <Dropdown item
                        compact
                        options={launcherMenu}
                        value={launcherValue}
                        onChange={(e, { name, value }) => {
                            if (value)
                                history.push(value);
                        }} />}



                    {user && (
                        <Menu.Menu position='right'>
                            <Dropdown item trigger={<span><Icon name='user' /> {user.fullName}</span>}>
                                <Dropdown.Menu>
                                    <Dropdown.Item disabled>
                                        <span style={{ lineHeight: 1.5 }}>
                                            Logged in as<br />
                                            <strong>{user.userName}</strong>
                                        </span>
                                    </Dropdown.Item>


                                    <UserAccount trigger={
                                        <Dropdown.Item icon='settings' text='Settings' />
                                    } />


                                    <Dropdown.Divider />
                                    <Dropdown.Item icon='log out' text="Log out" onClick={() => {
                                        tendrilApi.functions.logout();
                                        history.push("/");
                                    }} />
                                </Dropdown.Menu>
                            </Dropdown>

                        </Menu.Menu>)}

                    {(!user) && (
                        <Menu.Item position='right'>
                            <Login history={history} trigger={<Button primary as='a'>Log in</Button>} />
                        </Menu.Item>)}

                </Menu>
            </div>
        );
    }
}


export const TopNav = withTendrilApi(TopNavBase);


