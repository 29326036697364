import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { NotFound } from '../../components/NotFound';
import { BrowserRouter, Router, Switch, Route } from 'react-router-dom';


const Loading = () => <div>Loading...</div>;


const EmailConfirmation = Loadable({ loader: () => import('./EmailConfirmation').then(module => module.EmailConfirmation), loading: Loading });
const PasswordReset = Loadable({ loader: () => import('./PasswordReset').then(module => module.PasswordReset), loading: Loading });

const RegistrationExtEmailConfirmation = Loadable({ loader: () => import('./registrationext/RegistrationExtEmailConfirmation').then(module => module.RegistrationExtEmailConfirmation), loading: Loading });

export default class User extends Component {

    render() {
        return (
            <Switch>
                <Route exact path={`/user/EmailConfirmation`} component={EmailConfirmation} />
                <Route exact path={`/user/PasswordReset`} component={PasswordReset} />
                <Route exact path={`/user/RegistrationExt/EmailConfirmation`} component={RegistrationExtEmailConfirmation} />
                <Route path='*' component={NotFound} />
            </Switch>
        );
    }
}


