import React, { Component } from 'react';
import {
    Menu, Icon
} from 'semantic-ui-react';
import _ from 'lodash';



export class LeftNav extends Component {

    displayName = LeftNav.name;

    constructor(props) {
        super(props);

        this.accordionTitleClick = this.accordionTitleClick.bind(this);

        this.state = {};
    }

    accordionTitleClick(e, titleProps) {
        const { parentpath, path } = titleProps;

        // for each parent path, store which child is open
        if (this.state[parentpath] === path)
            this.setState({ [parentpath]: null }); // close
        else
            this.setState({ [parentpath]: path }); // closes existing, opens new
    }


    render() {
        const { location, history, match, navItems } = this.props;


        return (<Menu size='large' color='black' inverted vertical>
            {navItems.map((item, idx) => {

                if (item.key) return item;      // bit of a hack to allow supply of pre-made components. probably a better way

                const key = item.url ? item.url : item.label;

                const selected = location.pathname.startsWith(item.url);

                return (
                    <Menu.Item
                        key={key}
                        active={selected}
                        onClick={item.url && (() => {
                            if (_.isString(item.url))
                                history.push(item.url);
                            else
                                item.url();
                        })}>
                        {item.icon && <Icon name={item.icon} />}
                        {item.label}
                    </Menu.Item>);
            })}
        </Menu>);
    }

}

