import 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
//import registerServiceWorker from './registerServiceWorker';




import App from './App';


//const Page = Loadable({
//    loader: () => import('./Page'),
//    loading: Loading,
//});


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <Switch>
            <Route path="/" component={App} />
        </Switch>
    </BrowserRouter>
    ,
    rootElement);

// DJB disabled this
//registerServiceWorker();
