import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { TendrilApiProvider } from './components/TendrilApi';
import { NotFound } from './components/NotFound';
import { Home } from './areas/home/Home';
import User from './areas/user/User';


const Loading = () => <div>Loading...</div>;

const Admin = Loadable({
    loader: () => import('./areas/admin/Admin').then(module => module.Admin),
    loading: Loading
});

const Customer = Loadable({
    loader: () => import('./areas/customer/Customer').then(module => module.Customer),
    loading: Loading
});




export default class App extends Component {
    displayName = App.name;

    constructor(props) {
        super(props);
    }


    render() {

        return (
            <TendrilApiProvider>
                <Switch>
                    <Route exact path='/' component={Home} />

                    <Route path='/user' component={User} />

                    <Route path='/admin' component={Admin} />
                    <Route path='/customer/:customerOrgCode' component={Customer} />
                    {/* Also don't forget to route by customer name, not just id... eg /customer/Coates_Hire/... */}

                    <Route path="*" component={NotFound} />
                </Switch>
            </TendrilApiProvider>
        );
    }
}
