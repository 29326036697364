import React from 'react';
import { Message } from 'semantic-ui-react';

const flatMap = (f, arr) => arr.reduce((x, y) => [...x, ...f(y)], []);


/*
 * Props:
 * error:  object, or null to not show the error box
 * fields are
 * {
 *   "message" : "An error happened",
 *   "data" : { "email" : ["Email address was invalid"] }
 *   "status" : 400
 *   }
 * 
 */

export function ErrorMessage(props) {

    const { error } = props;

    if (!error)
        return null;

    let { data, message, status } = error;

    if (!(data || message || status))
        return <Message visible negative header="An unknown error occurred" />;

    if (!(data || message)) {
        switch (status) {
            case 400: message = 'A bad request was sent to the server.'; break;
            case 401: message = 'You need to log in to perform this action.'; break;
            case 403: message = 'You are not authorised to perform this action.'; break;
            case 404: message = 'The requested data could not be found.'; break;
            case 409: message = "Could not create or update as there's a conflict with existing data."; break;
            default: message = `The server returned an error (code ${status})`; break;
        }
    }

    if (!data)
        return (
            <Message negative header={message} />
        );

    const list = flatMap(k => data[k], Object.keys(data));
    switch (list.length) {
        case 0: return (
            <Message negative header={message} />
        );
        case 1: return (
            <Message negative header={message} content={list[0]} />
        );
        default: return (
            <Message negative header={message} list={list} />
        );
    }
}

