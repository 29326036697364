import React, { Component } from 'react';
import decode from 'jwt-decode';
import 'whatwg-fetch';
import _ from 'lodash';


export class FetchError extends Error {

    constructor(message, status = null, data = null) {
        //console.log(`FetchError ctor: ${message} ${status} ${data}`);
        super(message);
        this.status = status;
        this.data = data;
        this.name = "FetchError";
    }
}

export const NoneVerbBit = 0;
export const DeleteVerbBit = 1;
export const GetVerbBit = 2;
export const PatchVerbBit = 4;
export const PostVerbBit = 8;
export const PutVerbBit = 16;
export const AllVerbBits = DeleteVerbBit | GetVerbBit | PatchVerbBit | PostVerbBit | PutVerbBit;


// https://stackoverflow.com/questions/10593013/delete-cookie-by-name
function delete_cookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}


// Auth token will be refreshed at this interval, which is a network call and full UI redraw / reload, so don't make it too short.
// However too long and the UI won't reflect user permission changes (ie granted or revoked access to areas).

const claimsCheckIntervalMs = 60 * 60 * 1000;



// https://daveceddia.com/context-api-vs-redux/

export const TendrilApiContext = React.createContext();

export class TendrilApiProvider extends React.Component {

    constructor(props) {
        super(props);

        this.timerId = null;

        // usual js bind garbage
        this.checkClaimsAndReschedule = this.checkClaimsAndReschedule.bind(this);

        // Functions available to consumers

        this.functions = {
            fetch: this.fetch.bind(this),
            login: this.login.bind(this),
            logout: this.logout.bind(this),
            setToken: this.setToken.bind(this),
            accessAny: this.accessAny.bind(this),
            accessAll: this.accessAll.bind(this),
            refresh: this.refresh.bind(this)
        };

        this.state = {
            value: {
                token: null,
                claims: null,
                user: null,
                functions: this.functions
            },
        };
    }

    componentDidMount() {

        try {
            const token = localStorage.getItem('tendril_token');
            const userString = localStorage.getItem('tendril_user');
            const claims = token ? decode(token) : null;
            const user = userString ? JSON.parse(userString) : null;

            if ((claims && this.claimsHaveExpired(claims)) || !user) {
                this.deleteStoredData();
                this.setState({ value: { token: null, claims: null, user: null, functions: this.functions } });
            } else {
                // We have valid claims and user
                // But immediately refresh and get user data, so the UI shows latest state
                this.setState({ value: { token: token, claims: claims, user: user, functions: this.functions } },
                    this.refresh);
            }
        }
        catch (e) {
            // something bad has happened, just reset everything, make user log in again
            console.log(e);
            this.deleteStoredData();
            this.setState({ value: { token: null, claims: null, user: null, functions: this.functions } });
        }

        this.timerId = setTimeout(this.checkClaimsAndReschedule, claimsCheckIntervalMs);
    }


    componentWillUnmount() {
        clearTimeout(this.timerId);
    }


    checkClaimsAndReschedule() {

        const { claims } = this.state.value;

        if (claims) {
            if (this.claimsHaveExpired(claims)) {
                console.log("claims have expired");
                this.deleteStoredData();
                this.setState({ value: { token: null, claims: null, user: null, functions: this.functions } });
            }
            else {
                // Just do the refresh, this makes sure that UI will fairly quickly reflect any role changes.
                // Make sure we set a reasonable refresh interval.
                this.refresh();
            }
        }
        this.timerId = setTimeout(this.checkClaimsAndReschedule, claimsCheckIntervalMs);
    }

    claimsHaveExpired(claims) {
        if (!claims) return false;
        if (!claims.exp) return false;
        if (claims.exp > Date.now() / 1000) return false; // CHECK ME
        return true;
    }


    deleteStoredData() {
        localStorage.removeItem('tendril_token');
        localStorage.removeItem('tendril_user');
        delete_cookie('tendril_token');
    }




    render() {
        // NOTE
        // For consumers to re-render, we have to actually change the value of 'value'
        // I wanted to just pass down 'this', and maybe that's possible, but this follows the React docs: https://reactjs.org/docs/context.html#caveats
        return (
            <TendrilApiContext.Provider
                value={this.state.value}
            >
                {this.props.children}
            </TendrilApiContext.Provider>
        );
    }


    // General purpose fetch, parsing json on success and error
    //
    // On success (HTTP status code 200-299):
    //   - JSON-deserialised response body is returned, or null if no body
    //
    // On network error:
    //   - FetchError is thrown, with generic error message and no status code, no data
    //
    // On error returned by server
    //   - FetchError is thrown, with status code and data object of form { key : [values ...], ... }
    //


    async fetch(url, body, method = (body ? "POST" : "GET"), options) {
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.state.value.token) {
            headers['Authorization'] = 'Bearer ' + this.state.value.token;
        }

        let opts = {
            method,
            headers,
            body: JSON.stringify(body),
            ...options
        };

        let response = null;
        let json = null;
        try {
            response = await fetch(url, opts);
            const text = await response.text();
            if (text !== '')
                json = JSON.parse(text);
        }
        catch (e) {
            console.log(e);
            throw new FetchError("Error fetching data from server.");
        }

        if (response.ok)
            return json;

        throw new FetchError(json ? json.message : "Error fetching data from server.", response.status, json ? json.data : null);
    }


    setToken(token) {
        // Using jwt-decode npm package to decode the token
        const claims = decode(token);

        localStorage.setItem('tendril_token', token);
        this.setState(prevState => ({ value: { token: token, claims: claims, user: prevState.value.user, functions: this.functions } }));
    }


    async login(identifier, password) {
        try {
            const result = await this.fetch('/api/user/authenticate', { identifier, password });

            if (!result.token)
                throw new Error("Expected token in login result");

            if (!result.user)
                throw new Error("Expected user in login result");

            const claims = decode(result.token);

            localStorage.setItem('tendril_token', result.token);
            localStorage.setItem('tendril_user', JSON.stringify(result.user));
            this.setState(prevState => ({ value: { token: result.token, claims: claims, user: result.user, functions: this.functions } }));
        }
        catch (e) {
            console.log(e);
            this.deleteStoredData();
            this.setState({ value: { token: null, claims: null, user: null, functions: this.functions } });
            if (e.status === 401)
                throw new Error("Invalid username or password.");
            throw new Error("An error occurred during login.");
        }
    }


    logout() {
        console.log("logging out");
        // Clear user token and profile data from localStorage
        this.deleteStoredData();

        this.setState({ value: { token: null, claims: null, user: null, functions: this.functions } });
    }


    async refresh() {
        try {
            console.log("Refreshing auth token");

            const result = await this.fetch('/api/user/refresh', null, 'POST');

            if (!result.token)
                throw new Error("Expected token in refresh result");

            if (!result.user)
                throw new Error("Expected user in refresh result");

            const claims = decode(result.token);

            localStorage.setItem('tendril_token', result.token);
            localStorage.setItem('tendril_user', JSON.stringify(result.user));
            this.setState(prevState => ({ value: { token: result.token, claims: claims, user: result.user, functions: this.functions } }));
        }
        catch (e) {
            console.log(e);
            // don't throw an error. let the claims check retry, and eventually the auth token will expire
        }
    }





    // --- Check access to server resources by path 


    allowedVerbs(resource) {

        const { claims } = this.state.value;
        if (!claims) return 0;

        // keep resources on the claims object so it is forced to refresh when claims change
        if (!claims.resources) {
            // lazy init
            let resourceClaims = claims.Resource;
            if (!resourceClaims) return 0;
            if (!Array.isArray(resourceClaims)) resourceClaims = [resourceClaims];
            let resources = resourceClaims.map(c => {
                const parts = c.split('|');
                const path = parts[0];
                const verbs = parseInt(parts[1]);
                return { path, verbs };
            });
            resources = _.sortBy(resources, 'path').reverse();
            claims.resources = resources;
        }

        const { resources } = claims;

        let match = resources.find(r => resource.startsWith(r.path));
        
        if (!match) return 0;

        return match.verbs;
    }


    accessAny(resource, verbs = AllVerbBits) {
        const v = this.allowedVerbs(resource);
        //console.log(`allowedVerbs(${resource}) => ${v}`);

        return !!(v & verbs);
    }

    accessAll(resource, verbs = AllVerbBits) {
        const v = this.allowedVerbs(resource);
        //console.log(`allowedVerbs(${resource}) => ${v}`);

        return (v & verbs) === verbs;
    }
}




export function withTendrilApi(Component) {
    // ...and returns another component...
    return (props) => {
        // ... and renders the wrapped component with the context theme!
        // Notice that we pass through any additional props as well
        return (
            <TendrilApiContext.Consumer>
                {value => <Component {...props} tendrilApi={value} />}

            </TendrilApiContext.Consumer>
        );
    };
}

