import React, { Component } from 'react';
import { Message, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';



export function NotFound(props) {
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100vw",
            height: "100vh"
        }}>
            <div>
                <Message size='massive' negative header="Page not found" content="Sorry, that page does not exist." />
                <Segment size='big' padded='very' basic textAlign='center'>
                    <Link to="/">Home</Link>
                </Segment>
            </div>
        </div>
    );
}

