import _ from 'lodash';
import React, { Component } from 'react';
import { Form, Header, Button, Container, Divider, Message, Modal, Icon } from 'semantic-ui-react';
import { TopAndLeftNavLayout } from '../../components/TopAndLeftNavLayout';
import { withTendrilApi } from '../../components/TendrilApi';
import { Unauthorised } from '../../components/Unauthorised';
import { ErrorMessage } from '../../components/ErrorMessage';




const idleStep = "idle";
const updatingStep = "updating";
const successStep = "success";
const errorStep = "error";

class ForgotPasswordBase extends Component {

    constructor(props) {
        super(props);
        this.state = { step: idleStep, email: '' };
        this.resetState = this.resetState.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    resetState() {
        this.setState({ step: idleStep, email: '' });
    }

    async handleSubmit(event) {

        event.preventDefault();

        try {
            const { knownEmail, tendrilApi } = this.props;
            const email = knownEmail || this.state.email;

            if (email.length === 0)
                throw new Error("An email address is required.");

            this.setState({ step: updatingStep });

            await tendrilApi.functions.fetch("/api/user/requestpasswordreset", { email });

            this.setState({ step: successStep });
        }
        catch (e) {
            console.log(e);
            this.setState({ err: e, step: errorStep });
        }
    }

    handleChange(e, { name, value }) {
        this.setState({ [name]: value });
    }

    render() {
        const { trigger, knownEmail } = this.props;
        const { step, email, err } = this.state;

        return (
            <Modal trigger={trigger} closeIcon size='small'
                onOpen={this.resetState}
            >
                <Modal.Header>
                    <Header as='h2'>
                        <Icon name='question' />
                        <Header.Content>
                            Forgot password
                    </Header.Content>
                    </Header>
                </Modal.Header>

                <Modal.Content>
                    {step === successStep
                        ? <Message success header='Password reset instructions sent' content='Please check your email.' />
                        : <Form loading={step === updatingStep ? true : undefined}>
                            {knownEmail
                                ? <p>Click the button below and we will send instructions for resetting your password to <em>{knownEmail}</em>.</p>
                                : <p>Please enter your email address below and we will send instructions for resetting your password.</p>}

                            {!knownEmail &&
                                <Form.Input autoFocus label='Email' type='email' name='email' value={email} onChange={this.handleChange} />}

                            {step === errorStep && <ErrorMessage error={err} />}
                            <Button primary onClick={this.handleSubmit}> Reset password</Button>
                        </Form>}
                </Modal.Content>
            </Modal>);
    }
}


export const ForgotPassword = withTendrilApi(ForgotPasswordBase);
