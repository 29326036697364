import _ from 'lodash';
import React, { Component } from 'react';
import { Form, Header, Button, Container, Divider, Message, Modal, Icon, List, Segment } from 'semantic-ui-react';
import { TopAndLeftNavLayout } from '../../components/TopAndLeftNavLayout';
import { withTendrilApi } from '../../components/TendrilApi';
import { Unauthorised } from '../../components/Unauthorised';
import { ErrorMessage } from '../../components/ErrorMessage';
import { ForgotPassword } from './ForgotPassword';




const idleStep = "idle";
const updatingStep = "updating";
const successStep = "success";
const errorStep = "error";

class PasswordChanger extends Component {

    constructor(props) {
        super(props);
        this.state = { step: idleStep, oldPassword: '', newPassword: '', confirmNewPassword: '' };
        this.handleUpdatePassword = this.handleUpdatePassword.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    async handleUpdatePassword(event) {
        event.preventDefault();
        try {
            const { oldPassword, newPassword, confirmNewPassword } = this.state;

            if (oldPassword.length === 0)
                throw new Error("Old password is required.");

            if (confirmNewPassword !== newPassword)
                throw new Error("New passwords do not match.");

            if (newPassword.length === 0)
                throw new Error("New password cannot be blank.");

            const { tendrilApi } = this.props;
            const { claims } = tendrilApi;
            const userId = claims["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];

            this.setState({ step: updatingStep });

            const result = await tendrilApi.functions.fetch("/api/user/changepassword", { userId, oldPassword, newPassword });

            tendrilApi.functions.setToken(result.token);
            this.setState({ step: successStep, oldPassword: '', newPassword: '', confirmNewPassword: '' });
        }
        catch (e) {
            console.log(e);
            this.setState({ err: e, step: errorStep });
        }
    }

    handleChange(e, { name, value }) {
        this.setState({ [name]: value });
    }

    render() {
        const { step, err } = this.state;
        const { tendrilApi } = this.props;
        const { claims } = tendrilApi;

        return (<div>
            <Header content="Change password" />
            <Form
                loading={step === updatingStep ? true : undefined}
                success={step === successStep ? true : undefined}
                error={step === errorStep ? true : undefined}
            >
                <Form.Input label='Old password' type='password' name='oldPassword' value={this.state.oldPassword} onChange={this.handleChange} />
                <Form.Input label='New password' type='password' name='newPassword' value={this.state.newPassword} onChange={this.handleChange} />
                <Form.Input label='Confirm new password' type='password' name='confirmNewPassword' value={this.state.confirmNewPassword} onChange={this.handleChange} />

                <Message success content='Password updated!' />
                {step === errorStep && <ErrorMessage error={err} />}

                <Form.Group inline>
                    <Button onClick={this.handleUpdatePassword}>Update password</Button>

                    &nbsp;&nbsp;
                    <ForgotPassword
                        trigger={<a style={{ cursor: 'pointer' }} tabIndex={0}> I forgot my password</a>}
                        knownEmail={claims.Email}
                    />
                </Form.Group>

            </Form>
        </div>);
    }
}







class UserAccountBase extends Component {


    constructor(props) {
        super(props);

    }

    render() {
        const { trigger, tendrilApi } = this.props;
        const { claims, user, functions } = tendrilApi;
        if (claims) {

            if (functions.accessAny(`/api/user/${user.userId}`)) {

                return (
                    <Modal trigger={trigger} closeIcon>
                        <Modal.Header>
                            <Header as='h2'>
                                <Icon name='settings' />
                                <Header.Content>
                                    Account Settings
                                </Header.Content>
                            </Header>
                        </Modal.Header>

                        <Modal.Content scrolling>

                            <Segment basic>
                                <Header content="User details" />

                                <List>
                                    <List.Item>
                                        <List.Icon name='id badge' />
                                        <List.Content>
                                            <List.Header>Username</List.Header>
                                            <List.Description>{user.userName}</List.Description>
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Icon name='mail' />
                                        <List.Content>
                                            <List.Header>Email</List.Header>
                                            <List.Description>{user.email}</List.Description>
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Icon name='user circle' />
                                        <List.Content>
                                            <List.Header>Full name</List.Header>
                                            <List.Description>{user.fullName}</List.Description>
                                        </List.Content>
                                    </List.Item>
                                </List>
                            </Segment>

                            <Divider />
                            <Segment basic>
                                <PasswordChanger tendrilApi={tendrilApi} />
                            </Segment>
                        </Modal.Content>
                    </Modal>
                );

            }
        }
        return <Unauthorised />;
    }
}


export const UserAccount = withTendrilApi(UserAccountBase);
