import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {
    Container,
    Header,
    Icon,
    Segment,
    Message, Image
} from 'semantic-ui-react'
import { TopNav } from '../../components/TopNav';
import { TendrilApiContext, withTendrilApi, AllVerbBits } from '../../components/TendrilApi';




const Hero = ({ style }) => (
    // <div style={{
    //     ...style,
    //     background: 'linear-gradient(to bottom, #ffffff 0%, #86377b 2px, #86377b 20%, #27273c 80%, #1b1c1d 100%)',
    //     display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'
    // }}>
    <div style={{
        ...style,
        background: 'linear-gradient(to bottom, #cedef8 0%, #cedef8 2px, #c5e1f4 20%, #c5e1f4 80%, #cedef8 100%)',
        display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'
    }}>
        <Image size='large' src='/images/logos/dark-logo.svg' style={{ marginRight: '1.5em' }} />
        <Container textAlign='center' style={{ padding: '2em 0em' }}>
            <p>XR Content Management Portal</p>
        </Container>
        
    </div>
);



const Footer = ({ style }) =>
    <Segment vertical style={{ ...style, padding: '5em 0em', backgroundColor: '#cedef8' }}>
        <Container textAlign='center'>
            <p>ARQTEN &copy; 2024 <a href='https://realseriousgames.com'>Real Serious Games</a></p>
            <p>Unauthorised access prohibited.</p>
        </Container>
    </Segment>;






class HomeBase extends Component {

    constructor(props) {
        super(props);

        this.state = { user: null, isLoading: false };

        this.load = this.load.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
        this.load();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { tendrilApi } = this.props;
        const { claims } = tendrilApi;
        if (claims && claims !== prevProps.tendrilApi.claims) {
            this.load();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }



    async load() {
        const { tendrilApi } = this.props;
        const { claims, functions } = tendrilApi;

        try {
            this.setState({ user: null, isLoading: true });
            const userId = claims["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
            const user = await functions.fetch(`/api/user/${userId}`);
            if (this.mounted) {
                this.setState({ user });
            }
        }
        catch (e) {
            console.log(e);
        }
        if (this.mounted) { this.setState({ isLoading: false }); }
    }




    render() {

        const { tendrilApi, history } = this.props;
        const { claims, functions } = tendrilApi;
        const { user, isLoading } = this.state;

        return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>

                <TopNav {...this.props} />

                {!claims &&
                    <React.Fragment>
                        <Hero style={{ flexGrow: 1 }} />
                        <Footer style={{ flexShrink: 0 }} />
                    </React.Fragment>
                }


                {claims && !isLoading && user &&
                    <React.Fragment>

                        <div style={{
                            flexGrow: 1,
                            background: 'linear-gradient(to bottom, #cedef8 0%, #cedef8 2px, #c5e1f4 20%, #c5e1f4 80%, #cedef8 100%)',
                            display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'
                        }}>

                            <Header as='h1' textAlign='center' content='Welcome to ARQTEN' style={{ padding: '2rem' }} />
                            <Header as='h3' textAlign='center' content='Please select an area' style={{ paddingBottom: '2rem' }} />
                            <Container text style={{ overflowY: 'auto', padding: 20, marginBottom: 10 }}>

                                {!(user.isRsgUser || user.customers.length > 0) &&
                                    <Message warning
                                        icon='warning circle'
                                        header='No areas available!'
                                        content={<React.Fragment>
                                            <p>Although you have a Tendril account, you have not been granted access to any areas.</p>
                                            <p>Please contact your administrator or Real Serious Games.</p>
                                            <p>Once access has been granted, either reload this page or log out and log in for changes to take effect.</p>
                                        </React.Fragment>}
                                    />}




                            {user.isRsgUser &&
                                <Segment raised className='grow' onClick={() => history.push("/admin")} padded='very'>
                                        <Header as='h2' icon='cogs' content='ARQTEN admin' />
                                    </Segment>
                                }

                                {user.customers.map(c => (
                                    <Segment key={c.customerOrgCode} raised className='grow' onClick={() => history.push(`/customer/${c.customerOrgCode}`)} padded='very'>
                                        <Header as='h2' icon='cubes' content={c.customerName} />
                                    </Segment>))}

                            </Container>
                        </div>
                        <Footer style={{ flexShrink: 0 }} />
                    </React.Fragment>
                }
            </div>
        );
    }
}

export const Home = withTendrilApi(HomeBase);
