import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { TopNav } from './TopNav';
import { LeftNav } from './LeftNav';

export class TopAndLeftNavLayout extends Component {
    state = {
        leftNavOpen: true
    };

    render() {
        const { children, leftNavItems, ...rest } = this.props;
        const { leftNavOpen } = this.state;

        const topNavHeight = 70;
        const leftNavWidth = 250;

        return (
            <div>
                <div style={{ position: "fixed", left: 0, right: 0, top: 0, height: topNavHeight, display: "flex", flexDirection: "row", alignItems: "center", zIndex: 10 }}>
                    {leftNavItems &&
                        <div style={{ padding: 10 }}>
                            <Button basic as="a" icon="bars" onClick={() => this.setState({ leftNavOpen: !leftNavOpen })} />
                        </div>}

                    <div style={{ flex: "1", width: "100%", margin: 0 }}>
                        <TopNav {...rest} />
                    </div>
                </div>

                {leftNavItems &&
                    <div className="ui menu inverted" style={{
                        position: "fixed",
                        left: leftNavOpen ? 0 : -leftNavWidth,
                        width: leftNavWidth,
                        top: topNavHeight,
                        bottom: 0, transition: "left .5s",
                        marginTop: 0,
                        overflowY: "auto"
                    }}>
                        <LeftNav {...rest} navItems={leftNavItems} />
                    </div>
                }

                <div style={{
                    position: "fixed",
                    left: (leftNavItems && leftNavOpen) ? leftNavWidth : 0,
                    right: 0,
                    top: topNavHeight,
                    bottom: 0,
                    padding: "2em",
                    transition: "left .5s",
                    overflowY: "auto"
                }}>
                    <div style={{ transition: 'max-width .5s' }}>
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

